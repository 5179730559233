import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Chip,
  Dialog,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Center } from "../components/Center";
import { primaryColor } from "../utils/color";
import { Spacer } from "../components/Spacer";
import { grey } from "@mui/material/colors";
import { removingDigitsIndicatorsStyle } from "../style/global-style";
import {
  getOccupationInfoHeader,
  occupationTypeList,
  statesAndUTs,
} from "../constants/constants";
import { ActionSheetFrame } from "./action-sheet-frame";

export const PostRequestFormActionSheet = ({
  requestInfo,
  requestInfoDispatch,
  handleRequestSend,
  isRequestModalOpen,
  setIsRequestModalOpen,
}) => {
  const [isRequestSendDisabled, setIsRequestSendDisabled] = useState(true);

  useEffect(() => {
    const optionalFields = ["message"];

    const isEligible = Object.entries(requestInfo).every(
      ([key, value]) => optionalFields.includes(key) || value.trim() !== ""
    );

    setIsRequestSendDisabled(!isEligible);
  }, [requestInfo]);

  return (
    <>
      <Box>
        <ActionSheetFrame
          isActionSheetOpen={isRequestModalOpen}
          closeActionSheet={() => setIsRequestModalOpen(false)}
          header={"Request Form"}
          height={"80%"}
        >
          <Box sx={{ padding: "50px 20px 20px 20px" }}>
            <Stack>
              <Stack spacing={1}>
                <FieldHeading heading="For" isRequired />
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1.5 }}>
                  <CustomChip
                    postPropertyInfo={requestInfo}
                    postPropertyInfoDispatch={requestInfoDispatch}
                    type="requestFor"
                    label="Family"
                    value="Family"
                  />
                  <CustomChip
                    postPropertyInfo={requestInfo}
                    postPropertyInfoDispatch={requestInfoDispatch}
                    type="requestFor"
                    label="Men Only"
                    value="Men Only"
                  />
                  <CustomChip
                    postPropertyInfo={requestInfo}
                    postPropertyInfoDispatch={requestInfoDispatch}
                    type="requestFor"
                    label="Female Only"
                    value="Female Only"
                  />
                </Box>
              </Stack>
              <Spacer space={25} />
              <Stack spacing={1}>
                <Stack direction="row" alignItems="center" spacing={3}>
                  <FieldHeading heading="Number of Occupants" isRequired />
                  <OutlinedInput
                    placeholder="#"
                    type="number"
                    // endAdornment={
                    //   <InputAdornment position="end">Sqft</InputAdornment>
                    // }
                    value={requestInfo?.memberCount}
                    onChange={(event) => {
                      const value = event.target.value;
                      if (value.length <= 2) {
                        requestInfoDispatch({
                          filter: "memberCount",
                          value,
                        });
                      }
                    }}
                    sx={{
                      ...removingDigitsIndicatorsStyle,
                      width: "16%",
                      height: "34px",
                    }}
                  />
                </Stack>
              </Stack>
              <Spacer space={15} />
              <Stack spacing={1}>
                <FieldHeading heading="Full Name" isRequired />
                <TextField
                  type="text"
                  size="small"
                  variant="outlined"
                  inputProps={{ maxLength: 24 }}
                  placeholder="Your full name"
                  sx={{
                    ...textInputFieldStyle,
                  }}
                  value={requestInfo?.name}
                  onChange={(event) =>
                    requestInfoDispatch({
                      filter: "name",
                      value: event.target.value,
                    })
                  }
                />
              </Stack>
              <Spacer space={15} />
              <Stack spacing={1}>
                <FieldHeading heading="Occupation Type" isRequired />
                <Select
                  displayEmpty
                  value={requestInfo?.occupationType}
                  onChange={(event) => {
                    requestInfoDispatch({
                      filter: "occupationType",
                      value: event.target.value,
                    });
                  }}
                  input={<OutlinedInput />}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 68 * 4.5 + 8,
                        width: 250,
                      },
                    },
                  }}
                  sx={{
                    ...selectInputFieldStyle,
                    width: "100%",
                    height: "42px",
                  }}
                >
                  <MenuItem value="">
                    <Typography fontStyle="italic" sx={{ color: grey[500] }}>
                      Select
                    </Typography>
                  </MenuItem>
                  {occupationTypeList.map((type, i) => (
                    <MenuItem
                      key={i}
                      value={type.value}
                      sx={{
                        "&.Mui-selected": {
                          backgroundColor: `${primaryColor[200]}`,
                        },
                        "&.Mui-selected:hover": {
                          backgroundColor: `${primaryColor[200]}`,
                        },
                      }}
                    >
                      {type.label}
                    </MenuItem>
                  ))}
                </Select>
              </Stack>
              {requestInfo?.occupationType &&
                requestInfo?.occupationType !== "none" && (
                  <Box>
                    <Spacer space={10} />
                    <Stack spacing={1}>
                      <FieldHeading
                        heading={getOccupationInfoHeader(
                          requestInfo?.occupationType
                        )}
                        isRequired
                      />
                      <TextField
                        type="text"
                        size="small"
                        variant="outlined"
                        inputProps={{ maxLength: 38 }}
                        placeholder="Occupation info"
                        sx={{
                          ...textInputFieldStyle,
                        }}
                        value={requestInfo?.occupationInfo}
                        onChange={(event) =>
                          requestInfoDispatch({
                            filter: "occupationInfo",
                            value: event.target.value,
                          })
                        }
                      />
                    </Stack>
                  </Box>
                )}
              <Spacer space={15} />
              <Stack spacing={1}>
                <FieldHeading heading="Belong To" isRequired />
                <Select
                  displayEmpty
                  value={requestInfo?.belongsTo}
                  onChange={(event) => {
                    requestInfoDispatch({
                      filter: "belongsTo",
                      value: event.target.value,
                    });
                  }}
                  input={<OutlinedInput />}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 68 * 4.5 + 8,
                        width: 250,
                      },
                    },
                  }}
                  sx={{
                    ...selectInputFieldStyle,
                    width: "100%",
                    height: "42px",
                  }}
                >
                  <MenuItem value="">
                    <Typography fontStyle="italic" sx={{ color: grey[500] }}>
                      Select
                    </Typography>
                  </MenuItem>
                  {statesAndUTs.map((name) => (
                    <MenuItem
                      key={name}
                      value={name}
                      sx={{
                        "&.Mui-selected": {
                          backgroundColor: `${primaryColor[200]}`,
                        },
                        "&.Mui-selected:hover": {
                          backgroundColor: `${primaryColor[200]}`,
                        },
                      }}
                    >
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </Stack>
              <Spacer space={20} />
              <Stack spacing={1}>
                <FieldHeading heading="Message" />
                <Stack spacing={0}>
                  <TextField
                    type="text"
                    size="small"
                    multiline
                    rows="5"
                    inputProps={{ maxLength: 420 }}
                    variant="outlined"
                    placeholder="Your message to owner"
                    sx={{
                      ...textInputFieldStyle,
                    }}
                    value={requestInfo?.message}
                    onChange={(event) =>
                      requestInfoDispatch({
                        filter: "message",
                        value: event.target.value,
                      })
                    }
                  />
                  {/* <Stack direction="row" justifyContent="flex-end">
                    <Typography
                      alignSelf="end"
                      sx={{
                        color: grey[700],
                        paddingRight: "8px",
                        fontSize: "12px",
                      }}
                    >
                      {requestInfo?.message?.length || 0}
                    </Typography>
                  </Stack> */}
                </Stack>
              </Stack>
              <Spacer space={30} />
              <Button
                variant="contained"
                sx={{ width: "100%px" }}
                disabled={isRequestSendDisabled}
                onClick={handleRequestSend}
              >
                Send
              </Button>
              <Spacer space={20} />
            </Stack>
          </Box>
        </ActionSheetFrame>
      </Box>
    </>
  );
};

export const CustomChip = ({
  postPropertyInfo,
  postPropertyInfoDispatch,
  type,
  label,
  value,
}) => {
  return (
    <Chip
      label={label}
      color="primary"
      variant={postPropertyInfo?.[type] === value ? "filled" : "outlined"}
      onClick={() =>
        postPropertyInfoDispatch({
          filter: type,
          value: `${postPropertyInfo?.[type] === value ? "" : value}`,
        })
      }
      sx={{
        color: `${postPropertyInfo?.[type] === value ? "white" : grey[900]}`,
        backgroundColor: `${postPropertyInfo?.[type] === value ? "" : "white"}`,
        borderColor: `${grey[900]}`,
      }}
    />
  );
};

export const FieldHeading = ({ heading, isRequired }) => {
  return (
    <Stack direction="row" justifyContent="flex-start" spacing={0.2}>
      <Typography
        sx={{
          fontSize: "16px",
          fontWeight: "600",
          whiteSpace: "nowrap",
        }}
      >
        {heading}
      </Typography>
      {isRequired && (
        <Typography
          sx={{ fontSize: "16px", fontWeight: "500", color: primaryColor[800] }}
        >
          *
        </Typography>
      )}
    </Stack>
  );
};

const selectInputFieldStyle = {
  backgroundColor: "white",
  ".MuiOutlinedInput-notchedOutline": {
    borderColor: grey[500],
  },
};

const textInputFieldStyle = {
  backgroundColor: "white",
  // "& .MuiOutlinedInput-root": {
  //   "& fieldset": {
  //     borderColor: "black",
  //   },
  // },
};
