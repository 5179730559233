import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import { primaryBgColor, primaryColor } from "../utils/color";
import { useEffect, useRef, useState } from "react";
import ActionSheet from "actionsheet-react";
import { useSwipeable } from "react-swipeable";
import { HeaderTitle } from "./header-title";

export const ActionSheetFrame = ({
  children,
  isActionSheetOpen,
  closeActionSheet,
  header,
  height,
}) => {
  const ref = useRef();
  const [isActionSheetAtTop, setIsActionSheetAtTop] = useState(true);

  const openActionSheetFrame = () => {
    ref.current.open();
    window.history.pushState(
      window?.history?.state,
      null,
      window.location.pathname
    );
    // Waiting body to be stable before setting overflow hidden
    setTimeout(() => {
      document.body.style.overflow = "hidden";
    }, 500);
  };

  const closeActionSheetFrame = () => {
    ref.current.close();
    document.body.style.overflow = "";
    closeActionSheet();
  };

  const handleOnClose = () => {
    window.history.back();
    closeActionSheetFrame();
  };

  const handleScroll = (e) => {
    const scrollTop = e.target.scrollTop;
    setIsActionSheetAtTop(scrollTop === 0);
  };

  const handlers = useSwipeable({
    delta: 140,
    onSwipedDown: () => {
      if (isActionSheetAtTop) {
        window.history.back();
        closeActionSheetFrame();
      }
    },
  });

  useEffect(() => {
    const handlePopstate = (event) => {
      closeActionSheetFrame();
    };

    if (isActionSheetOpen) {
      openActionSheetFrame();
      window.addEventListener("popstate", handlePopstate);
    } else {
      closeActionSheetFrame();
    }

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, [isActionSheetOpen]);

  return (
    <>
      <Box>
        <ActionSheet
          ref={ref}
          onClose={handleOnClose}
          sheetStyle={{
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
            height,
            overflowY: "scroll",
            backgroundColor: `${primaryBgColor}`,
          }}
          mouseEnable={false}
          touchEnable={false}
          sheetTransition="transform 0.2s ease-in-out"
          bgTransition="opacity 0.3s ease-in-out, z-index 0.3s ease-in-out"
        >
          <Box
            onScroll={handleScroll}
            {...handlers}
            style={{ height: "100%", overflowY: "auto" }}
          >
            <Box
              sx={{
                bgcolor: "white",
                width: "100%",
                position: "fixed",
                top: "0px",
                zIndex: "9",
              }}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                px="8px"
                py="4px"
                bgcolor={primaryBgColor}
              >
                <Box></Box>
                <HeaderTitle
                  title={header}
                  style={{
                    fontSize: "18px",
                    fontWeight: "600",
                  }}
                />
                <Box></Box>
              </Stack>
              <Divider />
            </Box>
            {children}
          </Box>
        </ActionSheet>
      </Box>
    </>
  );
};
