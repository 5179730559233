import { Box, Stack, Typography } from "@mui/material";
import { MainPropertyCard } from "./MainPropertyCard";
import { MainMiniPropertyCard } from "./MainMiniPropertyCard";
import { primaryBgColor, primaryColor } from "../utils/color";
import { Spacer } from "./Spacer";
import { useCallback } from "react";

export const PropertyCardSection = ({ homePostsInfo, setDisableHandlers }) => {
  const {
    randomPropertyFirstBlock,
    randomPropertySecondBlock,
    recentlyAddedProperty,
    propertyUnder12000,
  } = homePostsInfo;

  const handleScroll = useCallback((e) => {
    const scrollLeft = e.target.scrollLeft;
    setDisableHandlers(scrollLeft > 0);
  }, []);

  return (
    <>
      <Box>
        <Box sx={{ paddingBottom: "0px" }}>
          <Stack spacing={1}>
            <CustomTopHeading text1="Recently" text2="Added Properties" />
            <Stack
              className="scrollable-content"
              spacing={3}
              direction="row"
              sx={horizontalCardScrollableStyle}
              onScroll={handleScroll}
            >
              {recentlyAddedProperty?.map((postData, i) => (
                <MainMiniPropertyCard key={i} postData={postData} />
              ))}
            </Stack>
          </Stack>
        </Box>
        <Spacer space={5} />
        <Stack spacing={1}>
          <CustomTopHeading text1="Best" text2="Properties For You" />
          <Box sx={{ paddingX: "8px" }}>
            <Stack spacing={3}>
              {randomPropertyFirstBlock?.map((postData, i) => (
                <MainPropertyCard
                  key={i}
                  postData={postData}
                  setDisableHandlers={setDisableHandlers}
                />
              ))}
            </Stack>
          </Box>
        </Stack>
        <Box sx={{ paddingTop: "20px", paddingBottom: "0px" }}>
          <Stack spacing={1}>
            <CustomTopHeading text1="Properties" text2="Under 12,000 /-" />
            <Stack
              className="scrollable-content"
              spacing={3}
              direction="row"
              sx={horizontalCardScrollableStyle}
              onScroll={handleScroll}
            >
              {propertyUnder12000?.map((postData, i) => (
                <MainMiniPropertyCard key={i} postData={postData} />
              ))}
            </Stack>
          </Stack>
        </Box>
        <Spacer space={5} />
        <Stack spacing={1}>
          <CustomTopHeading text1="Checkout" text2="Top Pick-Ups" />
          <Box sx={{ paddingX: "8px" }}>
            <Stack spacing={3}>
              {randomPropertySecondBlock?.map((postData, i) => (
                <MainPropertyCard
                  key={i}
                  postData={postData}
                  setDisableHandlers={setDisableHandlers}
                />
              ))}
            </Stack>
          </Box>
        </Stack>
      </Box>
    </>
  );
};

const CustomTopHeading = ({ text1, text2 }) => {
  return (
    <Stack direction="row" spacing={-1.5}>
      <Typography
        sx={{
          ...CustomTopHeadingStyle,
          color: "black",
        }}
      >
        {text1}
      </Typography>
      <Typography
        sx={{
          ...CustomTopHeadingStyle,
          color: primaryColor[800],
        }}
      >
        {text2}
      </Typography>
    </Stack>
  );
};

const CustomTopHeadingStyle = {
  fontSize: "20px",
  fontWeight: "600",
  paddingX: "10px",
  // fontFamily: "Calistoga, serif",
};

const horizontalCardScrollableStyle = {
  overflowX: "scroll",
  paddingBottom: "5px",
  paddingX: "10px",
  bgcolor: `${primaryBgColor}`,
};
