import {
  Box,
  Divider,
  ListItem,
  ListItemButton,
  Stack,
  Typography,
} from "@mui/material";
import { requestStatus } from "../constants/constants";
import { primaryColor } from "../utils/color";
import { FiberManualRecord } from "@mui/icons-material";
import { useState } from "react";

export const PostRequestsListItem = ({
  i,
  request,
  setModalInfo,
  handleSeenRequest,
}) => {
  const [seen, setSeen] = useState(request.seen);

  const handleOpenModal = () => {
    setModalInfo({
      isOpen: true,
      requestSatus: request?.requestStatus,
      modalData: request,
    });
    setSeen(true);
    if (request?.requestStatus === requestStatus.pending && !request?.seen) {
      handleSeenRequest(request?.requestUid);
    }
  };

  return (
    <>
      <Box onClick={handleOpenModal} sx={{ width: "100%" }}>
        <ListItem
          disablePadding
          sx={{
            textDecoration: "none",
            color: "black",
            fontFamily: "Neuton, serif",
          }}
        >
          <ListItemButton
            sx={{
              "&.MuiButtonBase-root": {
                paddingX: "3px",
                paddingY: "6px",
              },
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              spacing={0.5}
              sx={{ width: "100%", paddingX: "8px" }}
            >
              <Box sx={{ width: "14px " }}>
                {!seen && (
                  <FiberManualRecord
                    sx={{
                      color: "green",
                      fontSize: "14px",
                      paddingTop: "8px",
                    }}
                  />
                )}
              </Box>
              <Stack
                direction="row"
                alignItems="center"
                spacing={2.5}
                sx={{ width: "100%" }}
                justifyContent="space-between"
              >
                <Typography
                  sx={{
                    fontFamily: "Neuton, serif",
                    fontSize: "20px",
                  }}
                >
                  {request?.requestInfo?.name}
                </Typography>
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  alignSelf="flex-end"
                  sx={{ paddingRight: "10px" }}
                >
                  <Typography sx={{ fontSize: "14px" }}>
                    {request?.time?.toDate().getDate()}{" "}
                    {request?.time?.toDate().toLocaleString("default", {
                      month: "short",
                    })}
                    , {request?.time?.toDate().getFullYear()}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </ListItemButton>
        </ListItem>
      </Box>
    </>
  );
};
