import React, { useState } from "react";
import { grey } from "@mui/material/colors";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Spacer } from "../components/Spacer";
import { primaryColor } from "../utils/color";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  AMENITIES,
  FEATURES_LIST,
  FURNISHMENTS,
  PROPERTY_FEATURES_LIST,
  floors,
  getMandatoryAmenitiesCount,
  localities,
} from "../constants/constants";
import { FieldHeading } from "../pages/list-property";
import { removingDigitsIndicatorsStyle } from "../style/global-style";
import { AddCircle, ExpandMore, Info, RemoveCircle } from "@mui/icons-material";
import { TitleTextOkayModal } from "../modals/title-text-okay";
import { convertFirebaseTimestamp } from "../utils/generals";
import { CustomChip } from "./info-tab-list-property";

export const FeatureTabListProperty = ({
  postPropertyInfo,
  postPropertyInfoDispatch,
  isEditPost,
}) => {
  return (
    <>
      <Box>
        <Box>
          <Box>
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={
                  <ExpandMore
                    sx={{ paddingLeft: "5px", color: primaryColor[800] }}
                  />
                }
              >
                <FieldHeading heading="Furnishment" isRequired />
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
                  <FurnishmentChip
                    postPropertyInfo={postPropertyInfo}
                    postPropertyInfoDispatch={postPropertyInfoDispatch}
                    label={FURNISHMENTS.FURNISHED.label}
                    value={FURNISHMENTS.FURNISHED.value}
                  />
                  <FurnishmentChip
                    postPropertyInfo={postPropertyInfo}
                    postPropertyInfoDispatch={postPropertyInfoDispatch}
                    label={FURNISHMENTS.SEMI_FURNISHED.label}
                    value={FURNISHMENTS.SEMI_FURNISHED.value}
                  />
                  <FurnishmentChip
                    postPropertyInfo={postPropertyInfo}
                    postPropertyInfoDispatch={postPropertyInfoDispatch}
                    label={FURNISHMENTS.UNFURNISHED.label}
                    value={FURNISHMENTS.UNFURNISHED.value}
                  />
                </Box>
                <Spacer space={10} />
                {!postPropertyInfo?.furnishment?.type ||
                  (postPropertyInfo?.furnishment?.type !==
                    FURNISHMENTS.UNFURNISHED.value && (
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontStyle: "italic",
                        color: `${grey[700]}`,
                      }}
                    >
                      At least{" "}
                      {getMandatoryAmenitiesCount(
                        postPropertyInfo?.furnishment?.type
                      )}{" "}
                      Amenities are mandatory
                    </Typography>
                  ))}
                <Spacer space={6} />
                <Box>
                  {AMENITIES.map((amenity, index) => {
                    const isDisabled =
                      !postPropertyInfo?.furnishment?.type ||
                      postPropertyInfo?.furnishment?.type ===
                        FURNISHMENTS.UNFURNISHED.value;
                    return (
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        key={index}
                      >
                        <Typography>{amenity.label}</Typography>
                        <Stack direction="row" spacing={2} alignItems="center">
                          <IconButton
                            disabled={isDisabled}
                            onClick={() => {
                              const updatedAmenities = {
                                ...postPropertyInfo?.furnishment?.amenities,
                              };
                              if (
                                updatedAmenities?.[amenity.value]?.count &&
                                updatedAmenities[amenity.value].count > 1
                              ) {
                                updatedAmenities[amenity.value].count -= 1;
                              } else {
                                delete updatedAmenities[amenity.value]; // Remove the field when count is 0
                              }

                              postPropertyInfoDispatch({
                                filter: "furnishment",
                                value: {
                                  ...postPropertyInfo?.furnishment,
                                  amenities: updatedAmenities,
                                },
                              });
                            }}
                          >
                            <RemoveCircle
                              sx={{
                                fontSize: "18px",
                                color: isDisabled
                                  ? grey[400]
                                  : primaryColor[800],
                              }}
                            />
                          </IconButton>
                          <Typography>
                            {postPropertyInfo?.furnishment?.amenities?.[
                              amenity.value
                            ]?.count || 0}
                          </Typography>
                          <IconButton
                            disabled={isDisabled}
                            onClick={() => {
                              postPropertyInfoDispatch({
                                filter: "furnishment",
                                value: {
                                  ...postPropertyInfo?.furnishment,
                                  amenities: {
                                    ...postPropertyInfo?.furnishment?.amenities,
                                    [amenity.value]: {
                                      label: amenity.label,
                                      count:
                                        postPropertyInfo?.furnishment
                                          ?.amenities?.[amenity.value]?.count +
                                          1 || 1,
                                    },
                                  },
                                },
                              });
                            }}
                          >
                            <AddCircle
                              sx={{
                                fontSize: "18px",
                                color: isDisabled
                                  ? grey[400]
                                  : primaryColor[800],
                              }}
                            />
                          </IconButton>
                        </Stack>
                      </Stack>
                    );
                  })}
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Spacer space={20} />
          <Box>
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={
                  <ExpandMore
                    sx={{ paddingLeft: "5px", color: primaryColor[800] }}
                  />
                }
              >
                <FieldHeading heading="Property Features" />
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1.5 }}>
                  {FEATURES_LIST?.PROPERTY_FEATURES?.map((feature, index) => (
                    <FeatureChip
                      postPropertyInfo={postPropertyInfo}
                      postPropertyInfoDispatch={postPropertyInfoDispatch}
                      type="propertyFeatures"
                      value={feature}
                      key={index}
                    />
                  ))}
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Spacer space={20} />
          <Box>
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={
                  <ExpandMore
                    sx={{ paddingLeft: "5px", color: primaryColor[800] }}
                  />
                }
              >
                <FieldHeading heading="Society/Building Features" />
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1.5 }}>
                  {FEATURES_LIST?.SOCIETY_BUILDING_FEATURES?.map(
                    (feature, index) => (
                      <FeatureChip
                        postPropertyInfo={postPropertyInfo}
                        postPropertyInfoDispatch={postPropertyInfoDispatch}
                        type="societyBuildingFeatures"
                        value={feature}
                        key={index}
                      />
                    )
                  )}
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Box>
        <Box sx={{ paddingX: "12px" }}>
          {/* This added padding to have consistent experience with other tabs */}
          <Spacer space={20} />
          <Stack spacing={1}>
            <FieldHeading heading="Age of Property" />
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
              <CustomChip
                postPropertyInfo={postPropertyInfo}
                postPropertyInfoDispatch={postPropertyInfoDispatch}
                type="propertyAge"
                label="0-1 years"
                value="0-1 years"
              />
              <CustomChip
                postPropertyInfo={postPropertyInfo}
                postPropertyInfoDispatch={postPropertyInfoDispatch}
                type="propertyAge"
                label="1-5 years"
                value="1-5 years"
              />
              <CustomChip
                postPropertyInfo={postPropertyInfo}
                postPropertyInfoDispatch={postPropertyInfoDispatch}
                type="propertyAge"
                label="5-10 years"
                value="5-10 years"
              />
              <CustomChip
                postPropertyInfo={postPropertyInfo}
                postPropertyInfoDispatch={postPropertyInfoDispatch}
                type="propertyAge"
                label="10+ years"
                value="10+ years"
              />
            </Box>
          </Stack>
          <Spacer space={20} />
          <Stack spacing={1}>
            <FieldHeading heading="Power Back Up" />
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
              <CustomChip
                postPropertyInfo={postPropertyInfo}
                postPropertyInfoDispatch={postPropertyInfoDispatch}
                type="powerBackup"
                label="None"
                value="No"
              />
              <CustomChip
                postPropertyInfo={postPropertyInfo}
                postPropertyInfoDispatch={postPropertyInfoDispatch}
                type="powerBackup"
                label="Partial"
                value="Partial"
              />
              <CustomChip
                postPropertyInfo={postPropertyInfo}
                postPropertyInfoDispatch={postPropertyInfoDispatch}
                type="powerBackup"
                label="Full"
                value="Full"
              />
            </Box>
          </Stack>
          <Spacer space={20} />
          <Stack spacing={1}>
            <FieldHeading heading="About your property" />
            <Stack spacing={0}>
              <TextField
                type="text"
                size="small"
                multiline
                rows="7"
                inputProps={{ maxLength: 600 }}
                variant="outlined"
                placeholder="Share some details about your property"
                sx={{
                  ...textInputFieldStyle,
                }}
                value={postPropertyInfo?.propertyDescription}
                onChange={(event) =>
                  postPropertyInfoDispatch({
                    filter: "propertyDescription",
                    value: event.target.value,
                  })
                }
              />
              <Stack direction="row" justifyContent="space-between">
                <Typography
                  alignSelf="end"
                  sx={{
                    color: grey[700],
                    paddingLeft: "4px",
                    fontSize: "12px",
                    fontStyle: "italic",
                  }}
                >
                  Minimum 150 characters*
                </Typography>
                <Typography
                  alignSelf="end"
                  sx={{
                    color: grey[700],
                    paddingRight: "4px",
                    fontSize: "12px",
                  }}
                >
                  {postPropertyInfo?.propertyDescription?.length || 0}/600
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Box>
      </Box>
      <Spacer space={120} />
    </>
  );
};

export const FeatureChip = ({
  postPropertyInfo,
  postPropertyInfoDispatch,
  type,
  value,
}) => {
  return (
    <Chip
      label={"+" + value}
      color="primary"
      variant={
        postPropertyInfo?.features?.[type]?.includes(value)
          ? "filled"
          : "outlined"
      }
      onClick={() =>
        postPropertyInfoDispatch({
          filter: "features",
          value: {
            ...postPropertyInfo?.features,
            [type]: postPropertyInfo?.features?.[type]?.includes(value)
              ? postPropertyInfo?.features?.[type]?.filter(
                  (feature) => feature !== value
                )
              : [...postPropertyInfo?.features?.[type], value],
          },
        })
      }
      sx={{
        color: `${
          postPropertyInfo?.features?.[type]?.includes(value)
            ? "white"
            : grey[900]
        }`,
        backgroundColor: `${
          postPropertyInfo?.features?.[type]?.includes(value) ? "" : "white"
        }`,
        borderColor: `${grey[900]}`,
      }}
    />
  );
};

export const FurnishmentChip = ({
  postPropertyInfo,
  postPropertyInfoDispatch,
  label,
  value,
}) => {
  return (
    <Chip
      label={label}
      color="primary"
      variant={
        postPropertyInfo?.furnishment?.type === value ? "filled" : "outlined"
      }
      onClick={() =>
        postPropertyInfoDispatch({
          filter: "furnishment",
          value: {
            ...postPropertyInfo?.furnishment,
            amenities: {},
            type: `${
              postPropertyInfo?.furnishment?.type === value ? "" : value
            }`,
          },
        })
      }
      sx={{
        color: `${
          postPropertyInfo?.furnishment?.type === value ? "white" : grey[900]
        }`,
        backgroundColor: `${
          postPropertyInfo?.furnishment?.type === value ? "" : "white"
        }`,
        borderColor: `${grey[900]}`,
      }}
    />
  );
};

const textInputFieldStyle = {
  backgroundColor: "white",
  "& .MuiOutlinedInput-root": {
    // "& fieldset": {
    //   borderColor: "black",
    // },
  },
};
