import React, { useState } from "react";
import { grey } from "@mui/material/colors";
import {
  Box,
  Chip,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Spacer } from "../components/Spacer";
import { primaryColor } from "../utils/color";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { floors, localities } from "../constants/constants";
import { FieldHeading } from "../pages/list-property";
import { removingDigitsIndicatorsStyle } from "../style/global-style";
import { Info } from "@mui/icons-material";
import { TitleTextOkayModal } from "../modals/title-text-okay";
import { convertFirebaseTimestamp } from "../utils/generals";
import { GooglePlacesSearchForListing } from "./google-places-search-for-listing";
import { BhkChip, ParkingChip } from "./property-info-tab-list-property";

// Diabled keyboard popup for date picker
export const CustomInput = React.forwardRef((props, ref) => {
  return (
    <input {...props} ref={ref} className="custom-date-picker-input" readOnly />
  );
});

export const InfoTabListProperty = ({
  postPropertyInfo,
  postPropertyInfoDispatch,
  isEditPost,
}) => {
  return (
    <>
      <Box>
        <GooglePlacesSearchForListing
          postPropertyInfo={postPropertyInfo}
          postPropertyInfoDispatch={postPropertyInfoDispatch}
        />
        <Stack spacing={1}>
          <FieldHeading heading="Property Type" isRequired />
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2.5 }}>
            <CustomChip
              postPropertyInfo={postPropertyInfo}
              postPropertyInfoDispatch={postPropertyInfoDispatch}
              type="propertyType"
              label="Flat"
              value="Flat"
            />
            <CustomChip
              postPropertyInfo={postPropertyInfo}
              postPropertyInfoDispatch={postPropertyInfoDispatch}
              type="propertyType"
              label="House"
              value="House"
            />
          </Box>
        </Stack>
        <Spacer space={30} />
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack spacing={1} direction="row" alignItems="center">
            <FieldHeading heading="Block" />
            <TextField
              type="text"
              size="small"
              variant="outlined"
              placeholder="#"
              inputProps={{ maxLength: 3 }}
              sx={{
                ...textInputFieldStyle2,
                width: "70px",
              }}
              value={postPropertyInfo?.address?.block}
              onChange={(event) =>
                postPropertyInfoDispatch({
                  filter: "address",
                  value: {
                    ...postPropertyInfo?.address,
                    block: event.target.value.toUpperCase(),
                  },
                })
              }
            />
          </Stack>
          {postPropertyInfo?.propertyType === "Flat" && (
            <Stack spacing={1} direction="row" alignItems="center">
              <FieldHeading heading="Floor" isRequired />
              <TextField
                type="number"
                size="small"
                variant="outlined"
                placeholder="#"
                sx={{
                  ...textInputFieldStyle2,
                  ...removingDigitsIndicatorsStyle,
                  width: "60px",
                }}
                value={postPropertyInfo?.address?.floor}
                onChange={(event) => {
                  if (
                    event.target.value?.length <= 2 &&
                    event.target.value >= 0 &&
                    event.target.value < 100
                  ) {
                    postPropertyInfoDispatch({
                      filter: "address",
                      value: {
                        ...postPropertyInfo?.address,
                        floor: event.target.value,
                      },
                    });
                  }
                }}
              />
            </Stack>
          )}
        </Stack>
        <Spacer space={25} />
        <Stack spacing={1}>
          <FieldHeading heading="Apartment/Society Name" />
          <TextField
            type="text"
            size="small"
            variant="outlined"
            placeholder="Apartment/Society Name"
            sx={{ ...textInputFieldStyle }}
            inputProps={{ maxLength: 31 }}
            value={postPropertyInfo?.address?.apartmentSocietyName}
            onChange={(event) =>
              postPropertyInfoDispatch({
                filter: "address",
                value: {
                  ...postPropertyInfo?.address,
                  apartmentSocietyName: event.target.value,
                },
              })
            }
          />
        </Stack>
        <Spacer space={20} />
        <Stack spacing={1}>
          <FieldHeading heading="Near By" isRequired />
          <TextField
            type="text"
            size="small"
            variant="outlined"
            placeholder="Near by any landmark"
            sx={{ ...textInputFieldStyle }}
            inputProps={{ maxLength: 101 }}
            value={postPropertyInfo?.address?.nearBy}
            onChange={(event) =>
              postPropertyInfoDispatch({
                filter: "address",
                value: {
                  ...postPropertyInfo?.address,
                  nearBy: event.target.value,
                },
              })
            }
          />
        </Stack>
      </Box>
      <Spacer space={180} />
    </>
  );
};

export const PostTypeChip = ({
  isEditPost,
  postPropertyInfo,
  postPropertyInfoDispatch,
  label,
  value,
}) => {
  return (
    <Chip
      disabled={isEditPost}
      label={label}
      color="primary"
      variant={postPropertyInfo?.postType === value ? "filled" : "outlined"}
      onClick={() =>
        postPropertyInfoDispatch({
          filter: "postType",
          value: `${postPropertyInfo?.postType === value ? "" : value}`,
        })
      }
      sx={{
        color: `${postPropertyInfo?.postType === value ? "white" : grey[900]}`,
        backgroundColor: `${
          postPropertyInfo?.postType === value ? "" : "white"
        }`,
        borderColor: `${grey[900]}`,
      }}
    />
  );
};

export const CustomChip = ({
  postPropertyInfo,
  postPropertyInfoDispatch,
  type,
  label,
  value,
}) => {
  return (
    <Chip
      label={label}
      color="primary"
      variant={postPropertyInfo?.[type] === value ? "filled" : "outlined"}
      onClick={() =>
        postPropertyInfoDispatch({
          filter: type,
          value: `${postPropertyInfo?.[type] === value ? "" : value}`,
        })
      }
      sx={{
        color: `${postPropertyInfo?.[type] === value ? "white" : grey[900]}`,
        backgroundColor: `${postPropertyInfo?.[type] === value ? "" : "white"}`,
        borderColor: `${grey[900]}`,
      }}
    />
  );
};

export const PreferenceChip = ({
  postPropertyInfo,
  postPropertyInfoDispatch,
  label,
  value,
}) => {
  return (
    <Chip
      label={"+" + label}
      color="primary"
      variant={
        postPropertyInfo?.preferences?.includes(value) ? "filled" : "outlined"
      }
      onClick={() =>
        postPropertyInfoDispatch({
          filter: "preferences",
          value: postPropertyInfo?.preferences?.includes(value)
            ? [
                ...postPropertyInfo?.preferences?.filter(
                  (item) => item !== value
                ),
              ]
            : [...postPropertyInfo?.preferences, value],
        })
      }
      sx={{
        color: `${
          postPropertyInfo?.preferences?.includes(value) ? "white" : grey[900]
        }`,
        backgroundColor: `${
          postPropertyInfo?.preferences?.includes(value) ? "" : "white"
        }`,
        borderColor: `${grey[900]}`,
      }}
    />
  );
};

const textInputFieldStyle = {
  backgroundColor: "white",
  // "& .MuiOutlinedInput-root": {
  //   "& fieldset": {
  //     borderColor: "black",
  //   },
  // },
};

const textInputFieldStyle2 = {
  backgroundColor: "white",
  "& .MuiOutlinedInput-root": {
    height: "34px",
    // "& fieldset": {
    //   borderColor: "black",
    // },
  },
};

const selectInputFieldStyle = {
  backgroundColor: "white",
  ".MuiOutlinedInput-notchedOutline": {
    borderColor: "black",
  },
};
