import { Box, Typography } from "@mui/material";

export const AppVersion = () => {
  return (
    <>
      <Box>
        <Typography
          sx={{ fontSize: "10px", color: "black", fontWeight: "500" }}
        >
          @ version - 1.8.2
        </Typography>
      </Box>
    </>
  );
};
