import { Stack, Typography } from "@mui/material";
import { primaryColor } from "../utils/color";

export const HeaderTitle = ({ title, style }) => {
  const titleArray = title?.split(" ");
  return (
    <Stack direction="row" justifyContent="flex-start" spacing={1}>
      <Typography sx={style}>{titleArray[0]}</Typography>
      <Typography color={primaryColor[900]} sx={style}>
        {[...titleArray].slice(1).join(" ")}
      </Typography>
    </Stack>
  );
};
