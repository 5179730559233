import { useEffect, useReducer } from "react";

const initialRequestInfo = {
  requestFor: "",
  memberCount: "",
  name: "",
  occupationType: "",
  occupationInfo: "",
  belongsTo: "",
  message: "",
};

const requestInfoReducer = (requestInfo, action) => {
  switch (action.type) {
    case "reset":
      return initialRequestInfo;
    case "updateMemberCount":
      return {
        ...requestInfo,
        memberCount: {
          ...requestInfo.memberCount,
          [action.filter]: action.value,
        },
      };
    case "requestInfoFromLocalStorage":
      return action.value;
    default:
      return { ...requestInfo, [action.filter]: action.value };
  }
};

export const UseRequestInfoReducer = (isRequestModalOpen) => {
  const [requestInfo, requestInfoDispatch] = useReducer(
    requestInfoReducer,
    initialRequestInfo
  );

  useEffect(() => {
    if (isRequestModalOpen) {
      const requestInfoFromLocalStorage = localStorage.getItem("requestInfo");
      requestInfoDispatch({
        type: "requestInfoFromLocalStorage",
        value: JSON.parse(requestInfoFromLocalStorage) || initialRequestInfo,
      });
    }
  }, [isRequestModalOpen]);

  return { requestInfo, requestInfoDispatch };
};
