import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import { ActionSheetFrame } from "./action-sheet-frame";
import { Center } from "./Center";
import { primaryColor } from "../utils/color";
import { Spacer } from "./Spacer";
import {
  getOccupationInfoHeader,
  getOccupationTypeLabel,
  requestStatus,
} from "../constants/constants";
import { Phone } from "@mui/icons-material";
import { grey } from "@mui/material/colors";
import { color } from "framer-motion";

export const PostRequestInfoActionsheet = ({
  modalInfo,
  setModalInfo,
  handlePostRequest,
}) => {
  console.log("modalInfo - mylogs", modalInfo);
  const { requestInfo: modalRequestInfo } = modalInfo?.modalData || {};

  return (
    <>
      <Box>
        <ActionSheetFrame
          isActionSheetOpen={modalInfo?.isOpen}
          closeActionSheet={() => {
            setModalInfo({
              isOpen: false,
              requestSatus: "",
              modalData: null,
            });
          }}
          header={"Request Info"}
          height={"65%"}
        >
          <Box sx={{ padding: "50px 20px 20px 20px" }}>
            <Stack spacing={0.5}>
              <Spacer space={4} />
              <Typography sx={headerStyle}>Rental Preference</Typography>
              <Divider sx={{ height: "1px", bgcolor: primaryColor[800] }} />
              <Spacer space={3} />
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography sx={labelStyle}>Requesting For -</Typography>
                <Typography sx={valueStyle}>
                  {modalRequestInfo?.requestFor}
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography sx={labelStyle}>Number of Occupants -</Typography>
                <Typography sx={valueStyle}>
                  {modalRequestInfo?.memberCount}
                </Typography>
              </Stack>
              <Spacer space={3} />
              <Typography sx={headerStyle}>Personal Info</Typography>
              <Divider sx={{ height: "1px", bgcolor: primaryColor[800] }} />
              <Spacer space={3} />
              <Stack>
                <Typography sx={labelStyle}>Full Name</Typography>
                <Typography sx={valueStyle}>
                  {modalRequestInfo?.name}
                </Typography>
              </Stack>
              <Spacer space={2} />
              <Stack>
                <Typography sx={labelStyle}>Occupation Type</Typography>
                <Typography sx={valueStyle}>
                  {getOccupationTypeLabel(modalRequestInfo?.occupationType)}
                </Typography>
              </Stack>
              <Spacer space={2} />
              <Stack>
                <Typography sx={labelStyle}>
                  {getOccupationInfoHeader(modalRequestInfo?.occupationType)}{" "}
                </Typography>
                <Typography sx={valueStyle}>
                  {modalRequestInfo?.occupationInfo}
                </Typography>
              </Stack>
              <Spacer space={2} />
              <Stack>
                <Typography sx={labelStyle}>Belongs to</Typography>
                <Typography sx={valueStyle}>
                  {modalRequestInfo?.belongsTo}
                </Typography>
              </Stack>
              {modalRequestInfo?.message && (
                <>
                  <Spacer space={2} />
                  <Typography sx={headerStyle}>Additional Info</Typography>
                  <Divider sx={{ height: "1px", bgcolor: primaryColor[800] }} />
                  <Spacer space={2} />
                  <Stack>
                    <Typography sx={labelStyle}>Message</Typography>
                    <Typography sx={valueStyle}>
                      {modalRequestInfo?.message}
                    </Typography>
                  </Stack>
                </>
              )}
            </Stack>
            {modalInfo?.requestSatus === requestStatus.pending && (
              <Box>
                <Spacer space={15} />
                <Stack direction="row" spacing={1}>
                  <Typography
                    sx={{
                      fontSize: "13px",
                      fontStyle: "italic",
                      color: `${grey[700]}`,
                    }}
                  >
                    <span style={{ fontWeight: "700", fontStyle: "normal" }}>
                      Note:{" "}
                    </span>
                    When you accept the request, mobile numbers will be
                    exchanged between each other, allowing for direct contact.
                  </Typography>
                </Stack>
              </Box>
            )}
            <Spacer space={60} />
            {modalInfo?.requestSatus === requestStatus.pending && (
              <>
                <Spacer space={50} />

                <Stack alignItems="center">
                  <Button
                    variant="contained"
                    onClick={() =>
                      handlePostRequest(
                        modalInfo?.modalData?.requestUid,
                        "Approved"
                      )
                    }
                    sx={{
                      position: "fixed",
                      bottom: "80px",
                      width: "95%",
                    }}
                  >
                    Accept
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() =>
                      handlePostRequest(
                        modalInfo?.modalData?.requestUid,
                        "Rejected"
                      )
                    }
                    sx={{
                      bgcolor: "white",
                      position: "fixed",
                      bottom: "35px",
                      width: "95%",
                    }}
                  >
                    Reject
                  </Button>
                </Stack>
              </>
            )}
            {modalInfo?.requestSatus === requestStatus.approved && (
              <Stack alignItems="center">
                <Button
                  variant="contained"
                  sx={{
                    position: "fixed",
                    bottom: "35px",
                    width: "95%",
                  }}
                  startIcon={<Phone />}
                  onClick={async () => {
                    window.open(`tel:${modalInfo?.userContactNumber}`);
                    // To close the action sheet
                    window.history.back();
                    setModalInfo({
                      isOpen: false,
                      requestSatus: "",
                      modalData: null,
                    });
                  }}
                >
                  Call Now
                </Button>
              </Stack>
            )}
            {modalInfo?.requestSatus === requestStatus.rejected && (
              <Stack alignItems="center">
                <Button
                  variant="outlined"
                  sx={{
                    bgcolor: "white",
                    position: "fixed",
                    bottom: "35px",
                    width: "95%",
                  }}
                  onClick={() =>
                    handlePostRequest(
                      modalInfo?.modalData?.requestUid,
                      "Approved"
                    )
                  }
                >
                  <Stack>
                    <Typography sx={{ fontSize: "12px" }}>
                      Changed Your Decision
                    </Typography>
                    <Typography>Approve</Typography>
                  </Stack>
                </Button>
              </Stack>
            )}
            <Spacer space={20} />
          </Box>
        </ActionSheetFrame>
      </Box>
    </>
  );
};

const labelStyle = {
  // color: primaryColor[800],
  fontWeight: "550",
};

const valueStyle = {
  color: grey[900],
  fontSize: "15.5px",
  fontWeight: "500",
};

const headerStyle = {
  color: primaryColor[800],
  fontWeight: "550",
  fontSize: "17px",
};
