export const APP_NAME = "KnockMyDoor";

export const localities = [
  "Abul Fazal Enclave",
  "Ali Village",
  "Batla House",
  "Canal Colony",
  "Gaffar Manzil",
  "Ghafoor Nagar",
  "Hazi Colony",
  "Jamia Nagar",
  "Jasola Extension",
  "Jasola Vihar",
  "Jasola Village",
  "Jogabai",
  "Kalinidi Colony",
  "Khizarbad Village",
  "Madanpur Khadar",
  "Mashigarh Village",
  "Mujeeb Bagh",
  "Nai Basti",
  "New Friends Colony",
  "Noor Nagar",
  "Okhla Vihar",
  "Okhla Village",
  "Sarita Vihar",
  "Shaheen Bagh",
  "Taimoor Nagar",
  "Zakir Nagar",
].sort();

export const requestStatus = {
  pending: "Pending",
  approved: "Approved",
  rejected: "Rejected",
};

export const PAYMENT_STATUS = {
  PENDING: "pending",
  SUCCESS: "success",
  FAILED: "failed",
  INVALID: "invalid",
};

export const POST_STATUS = {
  ACTIVE: "active",
  IN_REVIEW: "in-review",
  ACTION_REQUIRED: "action-required",
  REMOVED: "removed",
  EXPIRED: "expired",
};

export const REQUEST_LIVE_STATUS = {
  ACTIVE: "active",
  REMOVED: "removed",
};

export const SAVED_LIVE_STATUS = {
  SAVED: "saved",
  UNSAVED: "unsaved",
};

export const floors = [
  "Ground",
  "First",
  "Second",
  "Third",
  "Fourth",
  "Fifth",
  "Sixth",
];

export const isRequiredMedia = {
  hall: true,
  bedrooms: true,
  kitchen: true,
  bathroom: true,
  front: false,
};

export const mediaLimits = {
  hall: 3,
  bedrooms: 6,
  kitchen: 2,
  bathroom: 2,
  front: 2,
};

export const mediaSorted = ["hall", "bedrooms", "kitchen", "bathroom", "front"];

export const FURNISHMENTS = {
  FURNISHED: {
    label: "Full",
    value: "Fully-Furnished",
  },
  SEMI_FURNISHED: {
    label: "Semi",
    value: "Semi-Furnished",
  },
  UNFURNISHED: {
    label: "Unfurnished",
    value: "Unfurnished",
  },
};

export const getMandatoryAmenitiesCount = (furnishmentType) => {
  switch (furnishmentType) {
    case FURNISHMENTS.FURNISHED.value:
      return 9;
    case FURNISHMENTS.SEMI_FURNISHED.value:
      return 5;
    case FURNISHMENTS.UNFURNISHED.value:
      return 0;
    default:
      return 0;
  }
};

export const AMENITIES = [
  {
    label: "Light",
    value: "light",
  },
  {
    label: "Fans",
    value: "fans",
  },
  {
    label: "AC",
    value: "ac",
  },
  {
    label: "TV",
    value: "tv",
  },
  {
    label: "Beds",
    value: "beds",
  },
  {
    label: "Wardrobe",
    value: "wardrobe",
  },
  {
    label: "Geyser",
    value: "geyser",
  },
  {
    label: "Sofa",
    value: "sofa",
  },
  {
    label: "Washing Machine",
    value: "washing-machine",
  },
  {
    label: "Stove",
    value: "stove",
  },
  {
    label: "Fride",
    value: "fride",
  },
  {
    label: "Water Purifier",
    value: "water-purifier",
  },
  {
    label: "Microwave",
    value: "microwave",
  },
  {
    label: "Chimney",
    value: "chimney",
  },
  {
    label: "Table",
    value: "table",
  },
  {
    label: "Chair",
    value: "chair",
  },
  {
    label: "Curtains",
    value: "curtains",
  },
  {
    label: "Exhaust Fan",
    value: "exhaust-fan",
  },
];

export const FEATURES_LIST = {
  PROPERTY_FEATURES: [
    "Corner Property",
    "Balcony",
    "High Celling Height",
    "Modular Kitchen",
    "Power Backup",
    "Piped Gas",
    "False Ceiling Lighting",
    "Recently Renovated",
    "Internet/Wifi Connectivity",
    "Prayer Room",
    "Terrace",
    "Centrally Air Conditioned",
    "Store Room",
    "Home Theatre",
    "Natural Light",
    "Airy Rooms",
    "Spacious Interiors",
  ],
  SOCIETY_BUILDING_FEATURES: [
    "Manintenance Staff",
    "Gated Society",
    "Fire Alarm",
    "Gym",
    "Swimming Pool",
    "Visitor Parking",
    "Low Density Society",
    "Maintenance Staff",
    "Park",
    "Library",
    "Intercom Facility",
    "Club House",
    "Waste Disposal",
    "Security Personnel",
    "Lift",
    "No Open Drainage around",
  ],
};

export const LIST_PROPERTY_OPTIONAL_FIELDS = [
  "address.block",
  "address.floor", // making floor disable here, it is covered in checkValidity method
  "address.apartmentSocietyName",
  "address.pincode",
  "maintenanceCharge",
  "parking",
  "lift",
  "propertyAge",
  "powerBackup",
  "features",
  "propertyDescription",
];

export const POST_CHARGE_PERCENTAGE = 9;

export const POST_DURATION_MONTHS = "one";

export const DARK_LOGO_URL =
  "https://firebasestorage.googleapis.com/v0/b/okhla-homes.appspot.com/o/logos%2Fdark-okhla-homes-logo.png?alt=media&token=421f902d-ee0d-47e7-a3c8-675d6f8d09d4";

export const LIGHT_LOGO_URL =
  "https://firebasestorage.googleapis.com/v0/b/okhla-homes.appspot.com/o/logos%2Flight-okhla-homes-logo.png?alt=media&token=1dfea415-183a-4f49-a8c1-1ea780a95485";

export const PLAYSTORE_URL =
  "https://play.google.com/store/apps/details?id=com.okhlahomes.twa&hl=en_US";
export const APPLE_STORE_URL =
  "https://apps.apple.com/in/app/okhla-homes/id6737781339";

export const RAZORPAY_KEY_ID_DEV = "rzp_test_iBUbFum4USwfMw";

export const RAZORPAY_KEY_ID_PROD = "rzp_live_jI3EBspdE6LjEm";

export const statesAndUTs = [
  // "Andaman and Nicobar Islands",
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chandigarh",
  "Chhattisgarh",
  // "Dadra and Nagar Haveli and Daman and Diu",
  "Delhi",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jammu and Kashmir",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Ladakh",
  // "Lakshadweep",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Puducherry",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttarakhand",
  "Uttar Pradesh",
  "West Bengal",
];

export const getOccupationTypeLabel = (occupationType) => {
  switch (occupationType) {
    case "business":
      return "Business - Self Employed";
    case "salaried":
      return "Salaried - Working Professional";
    case "student":
      return "Student";
    case "none":
      return "None";
    default:
      return "";
  }
};

export const getOccupationInfoHeader = (occupationType) => {
  switch (occupationType) {
    case "business":
      return "Business Type";
    case "salaried":
      return "Profession";
    case "student":
      return "Course of Study";
    case "none":
      return "";
    default:
      return "";
  }
};

export const occupationTypeList = [
  {
    label: getOccupationTypeLabel("business"),
    value: "business",
  },
  {
    label: getOccupationTypeLabel("salaried"),
    value: "salaried",
  },
  {
    label: getOccupationTypeLabel("student"),
    value: "student",
  },
  {
    label: getOccupationTypeLabel("none"),
    value: "none",
  },
];
