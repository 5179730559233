import { Box, Divider, List, Tab } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useContext, useState } from "react";
import TabContext from "@mui/lab/TabContext/TabContext";
import TabList from "@mui/lab/TabList/TabList";
import TabPanel from "@mui/lab/TabPanel/TabPanel";
import { ProfileContext } from "../context/ProfileContextProvider";
import { SomethingWentWrongModal } from "../components/SomethingWentWrongModal";
import { SomethingWentWrongScreen } from "../components/something-went-wrong-screen";
import { useSwipeable } from "react-swipeable";
import { UsePostrequestsInfo } from "../hooks/use-postrequests-info";
import { TextLoader } from "../components/text-loader";
import { PostRequestsHeader } from "../components/post-requests-header";
import { BackdropLoader } from "../components/backdrop-loader";
import { PostRequestInfoModal } from "../modals/post-request-info";
import { PostRequestsListItem } from "../components/post-requests-list-item";
import { PostRequestInfoActionsheet } from "../components/post-request-info-actionsheet";
import { primaryColor } from "../utils/color";
import { grey } from "@mui/material/colors";

export const PostRequests = () => {
  const location = useLocation();
  const { navigatedData } = location.state || {};
  const postUid = navigatedData?.postUid;
  const { user } = useContext(ProfileContext);
  const { userInfo } = user;
  const [tabValue, setTabValue] = useState("1");
  const [modalInfo, setModalInfo] = useState({
    isOpen: false,
    requestSatus: "",
    modalData: null,
  });
  const {
    postRequestsRes,
    handlePostRequestStatus,
    setHandlePostRequestStatus,
    handlePostRequest,
    handleSeenRequest,
  } = UsePostrequestsInfo(userInfo?.uid, postUid, modalInfo, setModalInfo);

  const handlers = useSwipeable({
    delta: 20,
    onSwipedLeft: () =>
      parseInt(tabValue, 10) < 3 &&
      setTabValue(String(parseInt(tabValue, 10) + 1)),
    onSwipedRight: () =>
      parseInt(tabValue, 10) > 1 &&
      setTabValue(String(parseInt(tabValue, 10) - 1)),
  });

  if (postRequestsRes?.isLoading) {
    return <TextLoader text="Loading..." />;
  }

  if (postRequestsRes?.isError) {
    return <SomethingWentWrongScreen />;
  }

  return (
    <>
      <BackdropLoader isLoading={handlePostRequestStatus?.isLoading} />
      <SomethingWentWrongModal
        state={handlePostRequestStatus}
        setState={setHandlePostRequestStatus}
      />
      {/* <PostRequestInfoModal
        modalInfo={modalInfo}
        setModalInfo={setModalInfo}
        handlePostRequest={handlePostRequest}
      /> */}
      <PostRequestInfoActionsheet
        modalInfo={modalInfo}
        setModalInfo={setModalInfo}
        handlePostRequest={handlePostRequest}
      />
      <Box>
        <PostRequestsHeader />
        <Box sx={{ width: "100%", height: "100vh" }} {...handlers}>
          <TabContext value={tabValue}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                position: "sticky",
                top: 50,
                zIndex: 99,
                backgroundColor: "white",
              }}
            >
              <TabList
                onChange={(event, newValue) => {
                  setTabValue(newValue);
                }}
                variant="fullWidth"
              >
                <Tab label="Requests" value="1" />
                <Tab label="Approved" value="2" />
                <Tab label="Rejected" value="3" />
              </TabList>
            </Box>
            <TabPanel value="1" sx={{ padding: "0px" }}>
              <Box>
                <List sx={{ paddingY: "0px" }}>
                  {postRequestsRes?.postRequestsData?.pending?.map(
                    (request, i) => (
                      <>
                        <PostRequestsListItem
                          key={i}
                          i={i}
                          request={request}
                          setModalInfo={setModalInfo}
                          handleSeenRequest={handleSeenRequest}
                        />
                        {postRequestsRes?.postRequestsData?.pending?.length !==
                          i + 1 && (
                          <Divider
                            variant="middle"
                            sx={{ bgcolor: grey[300] }}
                          />
                        )}
                      </>
                    )
                  )}
                </List>
              </Box>
            </TabPanel>
            <TabPanel value="2" sx={{ padding: "0px" }}>
              <Box>
                <List sx={{ paddingY: "0px" }}>
                  {postRequestsRes?.postRequestsData?.approved?.map(
                    (request, i) => (
                      <>
                        <PostRequestsListItem
                          key={i}
                          i={i}
                          request={request}
                          setModalInfo={setModalInfo}
                        />
                        {postRequestsRes?.postRequestsData?.approved?.length !==
                          i + 1 && (
                          <Divider
                            variant="middle"
                            sx={{ bgcolor: grey[300] }}
                          />
                        )}
                      </>
                    )
                  )}
                </List>
              </Box>
            </TabPanel>
            <TabPanel value="3" sx={{ padding: "0px" }}>
              <Box>
                <List sx={{ paddingY: "0px" }}>
                  {postRequestsRes?.postRequestsData?.rejected?.map(
                    (request, i) => (
                      <>
                        <PostRequestsListItem
                          key={i}
                          i={i}
                          request={request}
                          setModalInfo={setModalInfo}
                        />
                        {postRequestsRes?.postRequestsData?.rejected?.length !==
                          i + 1 && (
                          <Divider
                            variant="middle"
                            sx={{ bgcolor: grey[300] }}
                          />
                        )}
                      </>
                    )
                  )}
                </List>
              </Box>
            </TabPanel>
          </TabContext>
        </Box>
      </Box>
    </>
  );
};
