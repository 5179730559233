import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { deletePendingPost, hasChanges } from "../helper/helper";
import { TitleTextActionActionModal } from "../modals/title-text-action-action";
import { initailMedia, initialPostPropertyInfo } from "../reducer/reducer";

export const BackwardDiscardModal = ({
  postPropertyInfo,
  media,
  pendingPostInfo,
  userUid,
  isEditPost,
  handleSavePost,
}) => {
  const navigate = useNavigate();
  const [isDiscardModalOpen, setIsDiscardModalOpen] = useState({
    isOpen: false,
  });

  useEffect(() => {
    const handlePopstate = (event) => {
      if (
        !hasChanges(
          postPropertyInfo,
          initialPostPropertyInfo,
          media,
          initailMedia
        )
      ) {
        navigate("/");
        return;
      }

      setIsDiscardModalOpen({
        isOpen: true,
      });
      window.history.pushState(
        window?.history?.state,
        null,
        window.location.pathname
      );
    };

    if (!isEditPost) {
      window.history.pushState(
        window?.history?.state,
        null,
        window.location.pathname
      );
    }
    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, [postPropertyInfo, media]);

  const handleSaveChanges = () => {
    handleSavePost();
    setIsDiscardModalOpen({
      isOpen: false,
    });
    navigate("/");
  };

  const handleDiscardChanges = () => {
    deletePendingPost(userUid, pendingPostInfo);
    setIsDiscardModalOpen({
      isOpen: false,
    });
    navigate("/");
  };

  return (
    <>
      <TitleTextActionActionModal
        value="isOpen"
        state={isDiscardModalOpen}
        setState={setIsDiscardModalOpen}
        title="Save changes"
        text=" Do you want to save changes before leaving the page ?"
        actionText1="Discard"
        actionFunction1={handleDiscardChanges}
        actionText2="Save"
        actionFunction2={handleSaveChanges}
      />
    </>
  );
};
