import { Box, Chip, Divider, Stack, Typography } from "@mui/material";
import { Spacer } from "./Spacer";
import { primaryColor, cardColor, primaryBgColor } from "../utils/color";
import { Carousel } from "react-responsive-carousel";
import { Link, useLocation } from "react-router-dom";
import { Bookmark, Call, CurrencyRupee } from "@mui/icons-material";
import { POST_STATUS, mediaSorted } from "../constants/constants";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { green, grey, red } from "@mui/material/colors";
import {
  addNumberSuffix,
  getDisplayFullAddress,
  getPostTags,
} from "../utils/generals";
import { useCallback, useEffect } from "react";

export const MainPropertyCard = ({
  postData,
  isOwnerNavigatedFromMyPosts,
  setDisableHandlers,
}) => {
  const { postInfo, paymentStatus, postStatus, postType } = postData || {};
  const { pathname } = useLocation();
  const isMyPostsPage = pathname.includes("my-posts");
  const isPostActive = postData?.postStatus === POST_STATUS.ACTIVE;
  const mergedFeatures = postInfo?.features?.propertyFeatures.concat(
    postInfo?.features?.societyBuildingFeatures
  );
  const postTags = getPostTags(
    isMyPostsPage,
    paymentStatus,
    postStatus,
    postType
  );

  const handleScroll = useCallback((e) => {
    const scrollLeft = e.target.scrollLeft;
    setDisableHandlers(scrollLeft > 0);
  }, []);

  useEffect(() => {
    mergedFeatures?.sort(() => Math.random() - 0.5);
  }, []);

  return (
    <>
      <Box
        sx={{
          borderRadius: "10px",
          boxShadow: `3`,
          bgcolor: `${cardColor}`,
          textDecoration: "none",
        }}
        component={Link}
        to={`/view-property/${postData?.postUid}`}
        state={{
          navigatedData: {
            postInfo: postData,
          },
          navigatedFlag: {
            isOwnerNavigatedFromMyPosts,
          },
        }}
      >
        <Box sx={{ position: "relative" }}>
          <Carousel
            autoPlay={false}
            infiniteLoop={true}
            transitionTime={250}
            showArrows={false}
            showStatus={false}
            showIndicators={true}
            showThumbs={false}
            swipeScrollTolerance={60}
            preventMovementUntilSwipeScrollTolerance={true}
            onSwipeStart={() => setDisableHandlers(true)}
            onSwipeEnd={() => setDisableHandlers(false)}
          >
            {mediaSorted?.map((category, i) => {
              return postData?.media[category]?.map((property, i) => (
                <Box key={i}>
                  <Box>
                    <img
                      style={{
                        width: "100%",
                        aspectRatio: "16/9",
                        objectFit: "fill",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                        opacity: isPostActive ? "1" : "0.5",
                      }}
                      src={property}
                      alt=""
                    />
                  </Box>
                </Box>
              ));
            })}
          </Carousel>
          {postTags?.map((postTag, i) => (
            <Box
              sx={{
                position: "absolute",
                top: i * 40 + 25 + "px",
                left: "0px",
                zIndex: "9",
              }}
              key={i}
            >
              <Box
                sx={{
                  bgcolor: postTag?.variantColor,
                  color: "white",
                  px: "8px",
                  py: "1px",
                  borderTopRightRadius: "4px",
                  borderBottomRightRadius: "4px",
                }}
              >
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Bookmark fontSize="inherit" />
                  <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
                    {postTag?.tag}
                  </Typography>
                </Stack>
              </Box>
            </Box>
          ))}
        </Box>
        <Box
          sx={{
            py: "8px",
            opacity: isPostActive ? "1" : "0.5",
          }}
        >
          <Box sx={{ px: "20px" }}>
            <Stack direction="row" justifyContent="space-between">
              <Stack direction="row" alignItems="center" spacing={0.2}>
                <CurrencyRupee
                  sx={{
                    color: "black",
                    fontSize: "19px",
                    fontWeight: "600",
                    fontFamily: "'Lumanosimo', serif",
                  }}
                />
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "600",
                    fontFamily: "'Lumanosimo', serif",
                    color: "black",
                  }}
                >
                  {Math.floor(postInfo?.montlyRent).toLocaleString("en-IN")} /
                  Month
                </Typography>
              </Stack>
              <Typography
                sx={{
                  color: primaryColor[900],
                  fontSize: "14px",
                  fontWeight: "600",
                  fontFamily: "'Lumanosimo', serif",
                }}
              >
                {postInfo?.bhk} BHK
              </Typography>
            </Stack>
          </Box>
          <Spacer space={6} />
          <Divider variant="middle" sx={{ bgcolor: primaryColor[800] }} />
          <Spacer space={6} />
          <Box sx={{ px: "25px" }}>
            <Stack spacing={-0.4}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="flex-end"
              >
                <Typography
                  color={primaryColor[900]}
                  sx={{
                    fontSize: "17px",
                    fontWeight: "500",
                    fontFamily: "Calistoga, serif",
                  }}
                >
                  {postInfo?.address?.locality}
                </Typography>
                <Typography
                  sx={{
                    color: "black",
                    fontSize: "17px",
                    fontWeight: "500",
                    fontFamily: "Neuton, serif",
                  }}
                >
                  {postInfo?.furnishment?.type}
                </Typography>
              </Stack>
              <Typography
                color={primaryColor[900]}
                sx={{
                  color: grey[800],
                  fontSize: "17px",
                  fontWeight: "500",
                  lineHeight: "17px",
                  fontFamily: "Neuton, serif",
                }}
              >
                {postInfo?.address?.floor &&
                  postInfo?.propertyType === "Flat" && (
                    <span>
                      {postInfo?.address?.floor === "0" ? (
                        <span>Ground</span>
                      ) : (
                        <span>
                          {parseInt(postInfo?.address?.floor, 10)}
                          <span
                            style={{ fontSize: "10px", verticalAlign: "super" }}
                          >
                            {addNumberSuffix(
                              parseInt(postInfo?.address?.floor, 10)
                            )}
                          </span>
                        </span>
                      )}
                      {" floor, "}
                    </span>
                  )}
                {getDisplayFullAddress(
                  postInfo?.address?.fullAddress,
                  `${postInfo?.address?.locality}, `,
                  "india, ",
                  ", india"
                )}
              </Typography>
            </Stack>
            {mergedFeatures?.length > 0 && (
              <Box>
                <Spacer space={8} />
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Typography
                    sx={{
                      color: "black",
                      fontSize: "17px",
                      fontWeight: "500",
                      fontFamily: "Neuton, serif",
                      paddingBottom: "3px",
                    }}
                  >
                    Highlights:
                  </Typography>
                  <Stack
                    className="scrollable-content"
                    spacing={1}
                    direction="row"
                    sx={horizontalCardScrollableStyle}
                    onScroll={handleScroll}
                  >
                    {mergedFeatures?.map((feature, i) => (
                      <Chip
                        variant="outlined"
                        key={i}
                        label={feature}
                        sx={chip2Style}
                      />
                    ))}
                  </Stack>
                </Stack>
              </Box>
            )}
          </Box>
          <Spacer space={4} />
        </Box>
      </Box>
    </>
  );
};

const horizontalCardScrollableStyle = {
  overflowX: "scroll",
  bgcolor: `${primaryBgColor}`,
};

const chip2Style = {
  color: grey[900],
  bgcolor: grey[50],
  fontWeight: "500",
  borderRadius: "8px",
  height: "22px",
  paddingX: "0px",
  fontSize: "12px",
};
