import { Box, Stack, Typography } from "@mui/material";
import { Spacer } from "./Spacer";
import { cardColor, primaryColor } from "../utils/color";
import { Link } from "react-router-dom";
import { Bookmark, CurrencyRupee } from "@mui/icons-material";
import { getPostTags } from "../utils/generals";

export const MainMiniPropertyCard = ({ postData }) => {
  const { postInfo } = postData || {};

  const postTags = getPostTags(
    false,
    postData?.paymentStatus,
    postData?.postStatus,
    postData?.postType
  );

  return (
    <>
      <Box
        sx={{
          borderRadius: "5px",
          textDecoration: "none",
          bgcolor: `${cardColor}`,
        }}
        component={Link}
        to={`/view-property/${postData?.postUid}`}
        state={{
          navigatedData: {
            postInfo: postData,
          },
        }}
      >
        <Box sx={{ position: "relative" }}>
          <img
            style={{
              width: "250px",
              aspectRatio: "16/9",
              objectFit: "fill",
              borderRadius: "5px",
            }}
            src={postData?.media?.hall[0] || postData?.media?.bedrooms[0]}
            alt=""
          />
          {postTags?.map((postTag, i) => (
            <Box
              sx={{
                position: "absolute",
                top: i * 40 + 25 + "px",
                left: "0px",
                zIndex: "9",
              }}
              key={i}
            >
              <Box
                sx={{
                  bgcolor: postTag?.variantColor,
                  color: "white",
                  px: "8px",
                  py: "1px",
                  borderTopRightRadius: "4px",
                  borderBottomRightRadius: "4px",
                }}
              >
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Bookmark fontSize="inherit" />
                  <Typography sx={{ fontSize: "11px", fontWeight: "500" }}>
                    {postTag?.tag}
                  </Typography>
                </Stack>
              </Box>
            </Box>
          ))}
        </Box>
        <Spacer space={1} />
        <Box sx={{ py: "3px", px: "10px" }}>
          <Stack spacing={-0.8}>
            <Stack direction="row" justifyContent="space-between">
              <Stack direction="row" alignItems="center" spacing={0.2}>
                <CurrencyRupee
                  sx={{
                    color: "black",
                    fontSize: "19px",
                    fontWeight: "600",
                    fontFamily: "'Lumanosimo', serif",
                  }}
                />
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "600",
                    fontFamily: "'Lumanosimo', serif",
                    color: "black",
                  }}
                >
                  {Math.floor(postInfo?.montlyRent).toLocaleString("en-IN")} /
                  Month
                </Typography>
              </Stack>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontFamily: "'Lumanosimo', serif",
                  color: "black",
                }}
              >
                {postInfo?.bhk} BHK
              </Typography>
            </Stack>
            <Typography
              color={primaryColor[900]}
              sx={{
                fontSize: "18px",
                fontWeight: "500",
                fontFamily: "Neuton, serif",
              }}
            >
              {postInfo?.address?.locality}, {postInfo?.address?.state}
            </Typography>
          </Stack>
        </Box>
      </Box>
    </>
  );
};
