import {
  AddHomeWork,
  Bookmark,
  EmojiPeople,
  Info,
  ListAlt,
  Quiz,
  Reviews,
  Share,
  SupportAgent,
} from "@mui/icons-material";
import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  Stack,
  Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { primaryColor } from "../utils/color";
import { TopSectionMenuDrawer } from "./top-section-menu-drawer";
import { Spacer } from "./Spacer";
import { TitleTextActionModal } from "../modals/title-text-action";
import { useState } from "react";
import { SignOutSnackbar } from "./signout-snackbar";
import { shareApp, signOutUser } from "../helper/helper";
import { isIOS } from "react-device-detect";
import { APPLE_STORE_URL, PLAYSTORE_URL } from "../constants/constants";
import { useSwipeable } from "react-swipeable";

export const MenuDrawer = ({
  user,
  isDrawerOpen,
  disableHandlers,
  setIsDrawerOpen,
}) => {
  const { isUserSignedIn } = user;
  const navigate = useNavigate();
  const [isSignOutModalOpen, setIsSignOutModalOpen] = useState({
    isOpen: false,
  });
  const [isSignOutSnackbarOpen, setIsSignOutSnackbarOpen] = useState(false);

  const swipeHandlers = useSwipeable({
    delta: 60,
    onSwipedLeft: () => !disableHandlers && setIsDrawerOpen(!isDrawerOpen),
  });

  return (
    <>
      <SignOutSnackbar
        isOpen={isSignOutSnackbarOpen}
        setIsOpen={setIsSignOutSnackbarOpen}
      />
      <TitleTextActionModal
        value="isOpen"
        state={isSignOutModalOpen}
        setState={setIsSignOutModalOpen}
        title="Are you leaving ?"
        text="You can always log back to your account anytime. Do you want to sign out ?"
        actionText="Sign Out"
        actionFunction={() => {
          setIsSignOutModalOpen({
            isOpen: false,
          });
          setIsSignOutSnackbarOpen(true);
          signOutUser(navigate);
        }}
      />
      <Drawer
        anchor="left"
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        {...swipeHandlers}
      >
        <Stack
          height="100vh"
          justifyContent="space-between"
          sx={{ minWidth: "240px" }}
        >
          <Box>
            <TopSectionMenuDrawer user={user} />
            <Divider
              sx={{ height: "2px", bgcolor: primaryColor[800], width: "100%" }}
            />
            <List>
              <CustomListItem
                navigateTo="/list-property"
                icon={
                  <AddHomeWork
                    sx={{ fontSize: "22px", color: primaryColor[700] }}
                  />
                }
                title="Post Your Property"
              />
            </List>
            {isUserSignedIn && (
              <Box>
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={1}
                  paddingLeft="16px"
                >
                  <Typography
                    fontSize="22px"
                    fontWeight="bold"
                    color="black"
                    // fontFamily="Ultra, serif"
                    fontFamily="Neuton, serif"
                  >
                    My
                  </Typography>
                  <Typography
                    fontSize="20px"
                    // fontWeight="medium"
                    fontWeight="bold"
                    color={primaryColor[800]}
                    // fontFamily="Ultra, serif"
                    fontFamily="Neuton, serif"
                  >
                    Activities
                  </Typography>
                </Stack>
                <Divider variant="middle" sx={{ bgcolor: primaryColor[800] }} />
                <List sx={{ paddingY: "0px" }}>
                  <CustomListItem
                    navigateTo="/my-posts"
                    icon={
                      <ListAlt
                        sx={{ fontSize: "22px", color: primaryColor[700] }}
                      />
                    }
                    title="My Posts"
                  />
                  <CustomListItem
                    navigateTo="/my-requests"
                    icon={
                      <EmojiPeople
                        sx={{ fontSize: "22px", color: primaryColor[700] }}
                      />
                    }
                    title="My Requests"
                  />
                  <CustomListItem
                    navigateTo="/my-saved"
                    icon={
                      <Bookmark
                        sx={{ fontSize: "22px", color: primaryColor[700] }}
                      />
                    }
                    title="Saved"
                  />
                </List>
                <Spacer space={6} />
              </Box>
            )}
            <Box>
              <Stack
                direction="row"
                alignItems="center"
                spacing={1}
                paddingLeft="16px"
              >
                <Typography
                  fontSize="22px"
                  fontWeight="bold"
                  color="black"
                  fontFamily="Neuton, serif"
                >
                  Support
                </Typography>
                <Typography
                  fontSize="20px"
                  fontWeight="bold"
                  color={primaryColor[800]}
                  fontFamily="Neuton, serif"
                >
                  & Information
                </Typography>
              </Stack>
              <Divider variant="middle" sx={{ bgcolor: primaryColor[800] }} />
              <List sx={{ paddingY: "0px" }}>
                <CustomListItem
                  navigateTo="/about-us"
                  icon={
                    <Info sx={{ fontSize: "22px", color: primaryColor[700] }} />
                  }
                  title="About Us"
                />
                <CustomListItem
                  navigateTo="/faqs"
                  icon={
                    <Quiz sx={{ fontSize: "22px", color: primaryColor[700] }} />
                  }
                  title="FAQs"
                />
                <CustomListItem
                  navigateTo="/contact-us"
                  icon={
                    <SupportAgent
                      sx={{ fontSize: "22px", color: primaryColor[700] }}
                    />
                  }
                  title="Contact Us"
                />
              </List>
            </Box>
            <Box>
              <Spacer space={6} />
              <Stack
                direction="row"
                alignItems="center"
                spacing={1}
                paddingLeft="16px"
              >
                <Typography
                  fontSize="22px"
                  fontWeight="bold"
                  color="black"
                  fontFamily="Neuton, serif"
                >
                  App
                </Typography>
                <Typography
                  fontSize="20px"
                  fontWeight="bold"
                  color={primaryColor[800]}
                  fontFamily="Neuton, serif"
                >
                  Engagement
                </Typography>
              </Stack>
              <Divider variant="middle" sx={{ bgcolor: primaryColor[800] }} />
              <List sx={{ paddingY: "0px" }}>
                <CustomListItem
                  icon={
                    <Share
                      sx={{ fontSize: "22px", color: primaryColor[700] }}
                    />
                  }
                  onClick={() => shareApp()}
                  title="Share App"
                />
                <CustomListItem
                  icon={
                    <Reviews
                      sx={{ fontSize: "22px", color: primaryColor[700] }}
                    />
                  }
                  onClick={() =>
                    isIOS
                      ? window.open(APPLE_STORE_URL, "_self")
                      : window.open(PLAYSTORE_URL, "_self")
                  }
                  title="Review Us"
                />
              </List>
            </Box>
            <Spacer space={28} />
          </Box>
          <Box>
            <Divider />
            <Stack
              direction="row"
              justifyContent="center"
              sx={{ p: "8px" }}
              spacing={0.2}
            >
              <Typography
                sx={{
                  fontSize: "18px",
                  fontFamily: "Neuton, serif",
                }}
              >
                Developed by
              </Typography>
              <Typography
                fontSize="18px"
                color={primaryColor[800]}
                fontWeight="bold"
                fontStyle="italic"
                fontFamily="Caveat, cursive"
                paddingTop="2px"
              >
                Tlx
              </Typography>
            </Stack>
          </Box>
        </Stack>
      </Drawer>
    </>
  );
};

const CustomListItem = ({ navigateTo, onClick, icon, title }) => {
  return (
    <>
      <ListItem
        disablePadding
        component={Link}
        to={navigateTo}
        onClick={onClick}
        sx={{
          textDecoration: "none",
          color: "black",
          fontFamily: "Neuton, serif",
        }}
      >
        <ListItemButton>
          <Stack direction="row" alignItems="flex-end" spacing={3}>
            {icon}
            <Typography
              sx={{
                fontFamily: "Neuton, serif",
                fontSize: "20px",
                paddingRight: "10px",
              }}
            >
              {title}
            </Typography>
          </Stack>
        </ListItemButton>
      </ListItem>
    </>
  );
};
